<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <card>
          <div class="col-md-12">
            <div class="row">
              <base-input class="col-md-3" label="CLIENT">
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="RECHERCHER"
                  v-model="search_client"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
              <base-input class="col-md-3" label="CAMPAGNE">
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="RECHERCHER"
                  v-model="search_campagne"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>

              <base-input class="col-md-2" label="TYPE">
                <el-select filterable class="select-primary"
                  placeholder="TYPE"
                  clearable
                  v-model="TypeSelects.Type">
                  <el-option 
                    v-for="option in TypeSelects.ListType"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value">
                  </el-option>
                </el-select>
              </base-input>

              <div class="col-md-2">
                <base-input label="ANNÉE">
                  <el-select
                    class="select-primary"
                    placeholder="ANNÉE"
                    v-model="listeYears.currentyear"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in listeYears.years"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-2">
                <base-input label="MOIS">
                  <el-select
                    class="select-primary"
                    placeholder="MOIS"
                    v-model="listeMois.currentmois"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in listeMois.mois"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-12">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <button
                    @click="resetFilter"
                    class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                  >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                  <base-button 
                    v-if="$apollo.queries.getStatsValideMyCoreg.loading" 
                    loading 
                    round 
                    class="btn btn-round btn-just-icon btn-primary mb-2"
                  >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                  <button 
                    v-else @click="rechercher" 
                    class="btn btn-round btn-just-icon btn-primary mb-2"
                  >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                </div>
              </div>
            </div>
          </div>
        </card>
        <card>
          <div>
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-11 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
            </div>
            <el-table :data="filterRequest" show-summary :summary-method="getSummaries" row-class-name="uppercase-table-rows">
              <el-table-column 
                label="CLIENT" 
                prop="Client" 
                class="table-striped" 
                min-width="180"
                show-overflow-tooltip
                sortable>
              </el-table-column>
              <el-table-column
                label="CAMPAGNE"
                prop="Campagne"
                class="table-striped"
                min-width="180"
                show-overflow-tooltip
                sortable
                >
              </el-table-column>
              <el-table-column 
                label="TYPE"
                prop="Type" 
                class="table-striped"
                min-width="100"
                show-overflow-tooltip
                sortable>
              </el-table-column>
              <el-table-column 
                label="IMPRESSIONS" 
                prop="impression" 
                class="table-striped" 
                min-width="150"
                sortable>
                <template scope="scope">{{ formatThounsends(scope.row.impression) }}</template>
              </el-table-column>
              <el-table-column 
                label="Leads brut"
                prop="lead_brut"  
                min-width="150"
                class="table-striped" 
                sortable>
                <template scope="scope">{{ formatThounsends(scope.row.lead_brut) }}</template>
              </el-table-column>
              <el-table-column
                label="Leads net"  
                prop="lead_net" 
                min-width="150"
                class="table-striped" 
                sortable>
                <template scope="scope">{{ formatThounsends(scope.row.lead_net) }}</template>
              </el-table-column>
              <el-table-column  
                label="TARIF" 
                prop="prix_client"
                min-width="150"
                class="table-striped" 
                sortable>
                <template scope="scope">{{ formatThounsends(scope.row.prix_client, true, 2) + ' €' }}</template>
              </el-table-column>
              <el-table-column  
                label="VOLUME VALIDE" 
                prop="leads_valide"
                min-width="180"
                class="table-striped" 
                sortable>
                <template scope="props">
                  <base-input 
                    type="text"
                    @keyup.enter.native="changeChiffreClients($event, props.row)"
                    :value="props.row.leads_valide > 0 ? formatThounsends(props.row.leads_valide) : 0 "/>
                </template>
              </el-table-column>
              <el-table-column  
                label="% VALID" 
                prop="lead_valide"
                min-width="150"
                class="table-striped" 
                sortable
                :sort-method="CustomSort_prcVALID"
                >
                <template scope="props">{{ pourcentage(props.row.leads_valide, props.row.lead_brut) + ' %'  }}</template>
              </el-table-column>
              
              <el-table-column  
                label="VALIDER" 
                prop="etat"
                min-width="150"
                class="table-striped" 
                >
                <template scope="props">
                  <el-select class="select-primary"
                            :value="props.row.valide" 
							@change="validateStats($event, props.row)">
                    <el-option v-for="option in listEtats.etats"
                              class="select-success"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column  
                label="COMMENTAIRES" 
                prop="commentaire"
                min-width="190"
                class="table-striped" 
                sortable>
                <template scope="scope">{{ scope.row.commentaire }}</template>
              </el-table-column>

              <el-table-column 
                prop label="Action" 
                class="table-striped" 
                min-width="150">
                <template scope="scope">
                  <el-tooltip
                    content="VALIDER"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="showCommentaire(scope.row)"
                      class="like btn-link"
                      type="warning"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                </template>
              </el-table-column>

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div>
              <p
                class="card-category"
              >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>

    <modal
      :show.sync="commentaireShow"
      footerClasses="justify-content-center"
      class="modal-listbase"
      body-classes="p-0"
    >
      <card class="border-0 mb-0">
        <h3 slot="header" class="modal-title" v-if="commentaireAdd">
          AJOUTER LE COMMENTAIRE
        </h3>
        <h3 slot="header" class="modal-title" v-else>
          MODIFICATION LE COMMENTAIRE
        </h3>

        <div class="col-sm-12">
          <base-input label="Example textarea">
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="comments"></textarea>
          </base-input>

        </div>

        <div  class="text-center">
          <base-button
            @click.native="editCommentaire('add')"
            type="info"
            v-if="commentaireAdd"
            >AJOUTER
          </base-button>
          <base-button
            @click.native="editCommentaire('edit')"
            type="info"
            v-else
            >MODIFIER
          </base-button>
        </div>
      </card>
    </modal>


  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { Table, TableColumn } from "element-ui";
import gql from "graphql-tag";
import { BasePagination } from "src/components";
import swal from 'sweetalert2'
import { Modal } from "src/components";
import moment from 'moment'

export default {
  apollo: {
    getStatsValideMyCoreg: {
      query: gql`
        query ($annee: String ,$mois: String){
          getStatsValideMyCoreg(annee: $annee, mois: $mois) 
			{
				ID
				Client
				Campagne
				Type
				prix_client
				valide
				leads_valide
				lead_brut
				lead_net
				impression
				commentaire
			}
        }`,
        skip(){
          return this.SkipQuery
        },
        variables() {
          return {
            annee: this.listeYears.currentyear,
            mois: this.listeMois.currentmois
          }
        },
        result({ data, loader, networkStatus }) {
          this.Flag = true
          this.SkipQuery = true
          this.AllRequests  = data.getStatsValideMyCoreg
        }
    }
  },
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal
  },
  data() {
    return {
      commentaireAdd: true,
      commentaireShow: false,
      comments: "",
      id_sv: null,
      listEtats: {
        currentEtat: '',
        etats: [
            {value: '', label: '---'},
            {value: 'Oui', label: 'Oui'},
            {value: 'Non', label: 'Non'}
          ]
      },
      listeMois: {
          currentmois: "",
          mois: [
              { value: "01", label: "Janvier" },
              { value: "02", label: "Février" },
              { value: "03", label: "Mars" },
              { value: "04", label: "Avril" },
              { value: "05", label: "Mai" },
              { value: "06", label: "Juin" },
              { value: "07", label: "Juillet" },
              { value: "08", label: "Août" },
              { value: "09", label: "Septembre" },
              { value: "10", label: "Octobre" },
              { value: "11", label: "Novembre" },
              { value: "12", label: "Décembre" }
          ]
      },
      listeYears: {
          currentyear: "",
          years: []
      },
      TypeSelects : {
        Type: '',
        ListType: [
          { value: "coreg", label: "COREG"},
          { value: "sponso", label: "SPONSO"}
        ]
      },
      impressions: 0,
      leadsBrut: 0,
      leadsNet: 0,
      leadsValide: 0,
      pourcentageValidesGlobal: 0,
      search_client: "",
      search_campagne: "",
      AllRequests: [],
      SkipQuery: true,
      Flag: false,
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 500, 1000],
        total: 0
      },
      total: 0
    }
  },
  methods: {
    CustomSort_prcVALID(a, b) {
      return this.pourcentage(a.leads_valide, a.lead_brut) - this.pourcentage(b.leads_valide, b.lead_brut)
    },
    showCommentaire(row){
		console.log(row)
      this.comments = row.commentaire
      this.id_sv = row.ID
      if(this.comments == '' || this.comments == null){
		  console.log('1')
        this.commentaireAdd = true
      }else{
		  console.log('2')
        this.commentaireAdd = false
      }
      this.commentaireShow = true
    },
    editCommentaire(action){
      console.log(action, this.id_sv, this.comments)
      let id_sv = this.id_sv
      let comments = this.comments

      this.$apollo.mutate({
        // Query
        client: 'AUTH',
        mutation: gql`mutation ($id_sv: Int! $comments: String) {
            edit_comment_mycoreg(
              id_sv: $id_sv
              comments: $comments
            )
            {
              state
            }
          }`,
          variables: {
            id_sv: id_sv,
            comments: comments
          },
      }).then((data) => {
        if(data.data.edit_comment_mycoreg.state){
          this.filterRequest.forEach((value, index) => {
            if(value.ID == id_sv){
              this.filterRequest[index].commentaire = comments
            }
          });
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: "Le commentaire a été bien modifié.",
            duration: 5000
          });
          this.commentaireShow = false
        }
      }).catch((error) => {
        // Error
        console.error(error)
      })
    },
    changeChiffreClients(e, row){

      var newvalue = e.target.value;
      var id_sv = row.ID

      if(newvalue == '') {
        this.$notify({
          type: "warning",
          verticalAlign: "top",
          horizontalAlign: "right",
          message: "Entrer le chiffre client s'il vous plait!",
          duration: 5000
        });
      }else{
        swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Vous êtes en train de modifier le chiffre client!",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: '#988fc3',
          cancelButtonColor: '#27293D',
          confirmButtonText: 'Modifier',
          cancelButtonText: 'Annuler'
        }).then((result) => {
          if (result.value) {
            this.$apollo.mutate({
            // Query
            client: 'AUTH',
            mutation: gql`mutation ($id_sv: Int! $value: Int) {
                change_stats_leads_valide_mycoreg(
                  id_sv: $id_sv
                  value: $value
                )
                {
                  state
                }
              }`,
              variables: {
                id_sv: id_sv,
                value: newvalue
              },
            }).then((data) => {
              if(data.data.change_stats_leads_valide_mycoreg.state){
                this.filterRequest.forEach((value, index) => {
                  if(value.ID == id_sv){
                    this.filterRequest[index].leads_valide = newvalue
                  }
                });
                this.$notify({
                  type: "success",
                  verticalAlign: "top",
                  horizontalAlign: "right",
                  message: "Le chiffre client a été bien modifié.",
                  duration: 5000
                });
              }
            }).catch((error) => {
              // Error
              console.error(error)
            })
          }
        })
      }
    },
    validateStats(value, row){
		console.log(row.ID)
		console.log(value)
		swal.fire({
			title: 'Êtes-vous sûr?',
			text: "Vous êtes en train de modifier l'état!",
			width: 400,
			showCancelButton: true,
			confirmButtonColor: '#988fc3',
			cancelButtonColor: '#27293D',
			confirmButtonText: 'Modifier',
			cancelButtonText: 'Annuler'
		}).then((result) => {
			if (result.value) {
				this.$apollo.mutate({
					// Query
					client: 'AUTH',
					mutation: gql`mutation ($id_sv: Int! $valide: String) {
						validate_stats_mycoreg(
							id_sv: $id_sv
							valide: $valide
						)
						{
							state
						}
					}`,
					variables: {
						id_sv: row.ID,
						valide: value
					},
				}).then((data) => {
					this.$notify({
						type: "success",
						verticalAlign: "top",
						horizontalAlign: "right",
						message: "L'état a été bien modifié.",
						duration: 5000
					});
				}).catch((error) => {
					// Error
					console.error(error)
				})
			}
		})
    },
    rechercher() {
      this.SkipQuery = false
    },
    resetFilter(){
      this.search_client = ""
      this.search_campagne = ""
      this.TypeSelects.ListType = ""
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      value != null ? (value = parseFloat(value)) : (value = 0);
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = 'TOTAL:'
          return
        }
        if (index === 3) {
          sums[index] = this.formatThounsends(this.impressions)
          return
        }
        if (index === 4) {
          sums[index] = this.formatThounsends(this.leadsBrut)
          return
        }
        if (index === 5) {
          sums[index] = this.formatThounsends(this.leadsNet)
          return
        }
        if (index === 7) {
          sums[index] = this.formatThounsends(this.leadsValide)
          return
        }
        if (index === 8) {
          sums[index] = this.pourcentageValidesGlobal + ' %'
          return
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
	},
	pourcentage(leads_valide, lead_brut){
		leads_valide = Number(leads_valide)
		lead_brut = Number(lead_brut)
		let pourcentage = 0

		if(leads_valide> 0 && lead_brut>0){
			pourcentage = (leads_valide / lead_brut) * 100
		}
		return pourcentage.toFixed(1)
	}
  },
  computed: {
    filterRequest(){
      let list = this.AllRequests.filter(request => {
        return request.Client.toUpperCase().match(this.search_client.toUpperCase()) && 
               request.Campagne.toUpperCase().match(this.search_campagne.toUpperCase()) &&
               request.Type.toUpperCase().match(this.TypeSelects.Type.toUpperCase())
      })

      let impressions = 0
      let leadsBrut = 0
      let leadsNet = 0
      let leadsValide = 0
      let pourcentageValidesGlobal = 0
      list.forEach(stats_valide => {
        impressions += Number(stats_valide.impression)
        leadsBrut += Number(stats_valide.lead_brut)
        leadsNet += Number(stats_valide.lead_net)
        leadsValide += Number(stats_valide.leads_valide)
      });

      this.impressions = impressions
      this.leadsBrut = leadsBrut
      this.leadsNet = leadsNet
      this.leadsValide = leadsValide
      if(leadsValide>0 && leadsBrut>0){
        this.pourcentageValidesGlobal = ((leadsValide / leadsBrut) * 100).toFixed(1)
      }

      this.total = list.length
      return list.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  mounted(){
    this.listeMois.currentmois = moment().subtract(1, 'months').format('MM')
    this.listeYears.currentyear =  moment().subtract(1, 'months').format('YYYY')
    this.listeYears.years = Array.from({ length: 10 }, (_, index) => ({
      value: (moment().year() - index).toString(),
      label: (moment().year() - index).toString(),
    }))
    this.SkipQuery = false
  }
};
</script>

<style>
  .uppercase-table-rows {
    text-transform: uppercase;
  }
</style>