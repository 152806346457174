<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>MES DOCUMENTS OI</h3>
        <div class="card">
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                  <div class="row">
                    <div class="col-md-4 mb-2">
                      <div>
                        <h5 class="card-title">NUMÉRO D'OI</h5>
                      </div>
                      <div>
                        <base-input>
                          <el-select filterable class="select-primary"
                            clearable
                            placeholder="CHOISISSEZ"
                            v-model="OINumberSelects.OINumber">
                            <el-option 
                              v-for="option in OINumberSelects.ListOINumber"
                              class="select-primary"
                              :value="option.numero_oi"
                              :label="option.numero_oi"
                              :key="option.id">
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
                    <div class="col-md-4 mb-2">
                      <div>
                        <h5 class="card-title">DATE CRÉATION</h5>
                      </div>
                      <div>
                        <base-input>
                          <el-date-picker v-model="FromDate" value-format="yyyy-MM-dd" type="date" placeholder="YYYY-MM-DD"></el-date-picker>
                        </base-input>
                      </div>
                    </div>
                    <div class="col-md-4 mb-2">
                      <div>
                        <h5 class="card-title">DATE DE SIGNATURE</h5>
                      </div>
                      <div>
                        <base-input>
                          <el-date-picker v-model="SignDate" value-format="yyyy-MM-dd" type="date" placeholder="YYYY-MM-DD"></el-date-picker>
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
						      <div class="row">
                    <base-button 
                      v-if="$apollo.queries.listOi.loading" 
                      loading 
                      class="btn btn-just-icon btn-default mt-4 d-block" 
                    >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                    <button 
                      v-else 
                      class="btn btn-just-icon btn-default mt-4 d-block" 
                      @click="reset"
                    >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
						      </div>
					      </div>
              </div>
            </div>
          </div>
        </div> 
        <div class="card">
          <div class="card-body">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="col-xl-1 col-6">
                  <base-input>
                    <el-select
                      class="select-primary pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-11 col-6" >
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>
              </div>
              <el-table :data="filterlistOi">
                <el-table-column
                  prop= 'date_creation_oi'
                  label= 'Date Création'
                  minWidth='130'
                >
                  <template scope="scope">
                    {{ scope.row.date_creation_oi.split(" ")[0] }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop= 'numero_oi'
                  label= "Numéro d'OI"
                  minWidth='140'
                >
                </el-table-column>
                <el-table-column
                  label= 'Nom Campagne'
                  minWidth='170'
                >
                  <template scope="scope">
                    <!-- PERF -->
                    <template v-if="scope.row.oiMyaffil && scope.row.oiMyaffil.nom_campagne && scope.row.oiMyaffil.nom_campagne.length < 15">
                      {{ scope.row.oiMyaffil.nom_campagne.toUpperCase() }},
                    </template>
                    <template v-else-if="scope.row.oiMyaffil&& scope.row.oiMyaffil.nom_campagne && scope.row.oiMyaffil.nom_campagne.length >= 15">
                      <el-tooltip
                        :content="scope.row.oiMyaffil.nom_campagne"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <label>
                          {{ scope.row.oiMyaffil.nom_campagne.slice(0,15).toUpperCase() }}...,
                        </label>
                      </el-tooltip>
                    </template>
                    <!-- PERF -->
                    <!-- COREG -->
                    <template v-if="scope.row.oiMycoreg && scope.row.oiMycoreg.campagne && scope.row.oiMycoreg.campagne.length < 15">
                      {{ scope.row.oiMycoreg.campagne.toUpperCase() }},
                    </template>
                    <template v-else-if="scope.row.oiMycoreg && scope.row.oiMycoreg.campagne && scope.row.oiMycoreg.campagne.length >= 15">
                      <el-tooltip
                        :content="scope.row.oiMycoreg.campagne"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <label>
                          {{ scope.row.oiMycoreg.campagne.slice(0,15).toUpperCase() }}...,
                        </label>
                      </el-tooltip>
                    </template>
                    <!-- COREG -->
                    <!-- MARKET -->
                    <template v-if="scope.row.oiLeadmarket && scope.row.oiLeadmarket.nom_campagne && scope.row.oiLeadmarket.nom_campagne.length < 15">
                      {{ scope.row.oiLeadmarket.nom_campagne.toUpperCase() }},
                    </template>
                    <template v-else-if="scope.row.oiLeadmarket && scope.row.oiLeadmarket.nom_campagne && scope.row.oiLeadmarket.nom_campagne.length >= 15">
                      <el-tooltip
                        :content="scope.row.oiLeadmarket.nom_campagne"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <label>
                          {{ scope.row.oiLeadmarket.nom_campagne.slice(0,15).toUpperCase() }}...,
                        </label>
                      </el-tooltip>
                    </template>
                    <!-- MARKET -->
                    <!-- Datadeal -->
                    <template v-if="scope.row.oiDatadeal && scope.row.oiDatadeal.nom_campagne && scope.row.oiDatadeal.nom_campagne.length < 15">
                      {{ scope.row.oiDatadeal.nom_campagne.toUpperCase() }},
                    </template>
                    <template v-else-if="scope.row.oiDatadeal && scope.row.oiDatadeal.nom_campagne && scope.row.oiDatadeal.nom_campagne.length >= 15">
                      <el-tooltip
                        :content="scope.row.oiDatadeal.nom_campagne"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <label>
                          {{ scope.row.oiDatadeal.nom_campagne.slice(0,15).toUpperCase() }}...,
                        </label>
                      </el-tooltip>
                    </template>
                    <!-- Datadeal -->
                  </template>
                </el-table-column>
                <el-table-column
                  prop= 'montant_total'
                  label= 'Montant (HT)'
                  minWidth='140'
                >
                  <template scope="scope">
                    {{ (scope.row.montant_total) ? scope.row.montant_total.toLocaleString() : 0 }} €
                  </template>
                </el-table-column>
                <el-table-column
                  label="Platforme"
                  :min-width="120"
                  class="table-striped"
                >
                  <template scope="scope">
                    <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.oiMyaffil">
                      <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                    <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.oiMycoreg">
                      <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                    <span href="#" class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.oiLeadmarket">
                      <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                    <span href="#" class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.oiDatadeal">
                      <svg version="1.1" id="Calque_1" fill="#f3ac9a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" align="right" label="Actions">
                  <div slot-scope="props">
                    <el-tooltip
                      v-if="props.row.status === 'completed'"
                      content="Paiement"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="Payment(props.row)"
                        :disabled="props.row.paid == 'YES'"
                        class="btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-cart"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      content="PDF"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handleDownload(props.$index, props.row)"
                        class="btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-document"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      v-if="props.row.status == 'completed'"
                      content="OI SIGNÉ"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handleSignResult(props.$index, props.row)"
                        class="like btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-check-2"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="props.row.status == 'ready'"
                      content="en attend votre signature"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                    <a :href="props.row.signatureUrl" target="_blank">
                      <base-button
                        class="like btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-upload"></i>
                      </base-button>
                      </a>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :show.sync="ShowPDF"
      body-classes="p-0"
      class="modal-listbase"
      :show-close="true"
      >
        <base-button style="float: right;" @click.native="ShowPDF = false" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <object :data="PDFURL" type="application/pdf" style="width: 100%;height: 900px;">
          <iframe :src="PDFURL"></iframe>
        </object>
    </modal>
  </div>
</template>

<script>
import {Select, Option ,DatePicker} from 'element-ui'
import { Table, TableColumn } from 'element-ui';
import { BasePagination } from 'src/components';
import gql from 'graphql-tag'
import axios from 'axios';
import {Modal} from 'src/components'

export default {
  apollo: {
    listOi :
    { 
      client: 'AUTH',
      query: gql` query ($id_annonceur: Int) {
        listDocumentOi (id_annonceur: $id_annonceur) {
          id_oi
          date_creation_oi
          numero_oi
          status
          montant_total
          signatureUrl
          transactionId
          paid
          oiMyaffil{
            nom_campagne
          }
          oiMycoreg{
            campagne
          },
          oiLeadmarket {
            nom_campagne
          },
          oiDatadeal {
            nom_campagne
          }
        }
      }`,
      fetchPolicy: 'network-only',
      variables () {
        return {
          id_annonceur: JSON.parse(localStorage.getItem('utilisateur')).id
        }
      },
      result({ data, loader, networkStatus }) {
        this.tableData = data.listDocumentOi
        this.tableData.forEach(oi => {
          if ((oi.status === "completed" || oi.status === "ready"))
            this.OINumberSelects.ListOINumber.push({"numero_oi": oi.numero_oi})
        });
      },
      update: data => data.listDocumentOi
    },
  },
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    Modal
  },
  data() {
    return {
      PDFURL: '',
      ShowPDF: false,
      total: 0,
      searchedData: [],
      FromDate: '',
      SignDate: '',
      OINumberSelects: {
        OINumber: '',
        ListOINumber: []
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      tableColumns: [],
      tableData: []
    }
  },
  watch: {
    FromDate(value) {
      if(this.FromDate == null) {
        this.FromDate = ""
      }
    }
  },
  computed: {
    filterlistOi() {
      var list = null
      try {
        list = this.listOi.filter((oi) => {
          if(oi.date_signature == null)
            oi.date_signature = "null"
          
          return oi.numero_oi.match(this.OINumberSelects.OINumber) &&
                 oi.date_signature.match(this.SignDate || "")  &&
                 oi.date_creation_oi.match(this.FromDate) &&
                 (oi.status === "completed" || oi.status === "ready")
        })
        this.total = list.length
        return list.slice(this.from, this.to);
      } catch (error) {
        return null
      }
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  methods: {
    reset() {
      this.OINumberSelects.ListOINumber = ""
      this.FromDate = ""
      this.SignDate = ""
    },
    handleDownload($index, row){
      const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/pdf"; //for server test
      let accessToken = localStorage.getItem("token_jwt");
      let formData = new FormData()
      formData.append('id_oi_globale', row.id_oi)
      this.axios
        .post(url_api,formData , {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
          responseType: "blob"
        }).then(function(response) {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          this.ShowPDF = true
          this.PDFURL = window.URL.createObjectURL(blob)
        }.bind(this))
        .catch(function (error) {
          console.log(error.message);
        })
    },
    handleSignResult($index, row){
      const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/signvalide"; //for server test
      let accessToken = localStorage.getItem("token_jwt");
      let formData = new FormData()
      formData.append('transactionId', row.transactionId)
      this.axios
        .post(url_api,formData , {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
          responseType: "blob"
        }).then(function(response) {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          this.ShowPDF = true
          this.PDFURL = window.URL.createObjectURL(blob)
        }.bind(this))
        .catch(function (error) {
          console.log(error.message);
        })
    },
    Payment(row) {
      localStorage.setItem("id_oi", row.id_oi)
      this.$router.push({ name: "prepaiement"})
    }
  }
}
</script>