<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>APPEL A FACTURE</h3>
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-8 col-lg-12 col-12">
                <div class="row">
                  <div class="col-md-3">
                    <base-input label="Année">
                      <el-select class="select-primary"
                        placeholder="CHOISISSEZ"
                        v-model="YearsSelects.Year">
                        <el-option
                          v-for="option in YearsSelects.YearList"
                          class="select-primary"
                          :value="option.value"
                          :label="option.value"
                          :key="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- <div class="col-xl-4 col-lg-12 col-12"><br>
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <base-button
                    v-if="$apollo.queries.liste_supports.loading"
                    loading
                    round
                    class="btn btn-round btn-just-icon btn-primary mb-2"
                  >Chargement</base-button>
                  <button
                    @click="resetFilter"
                    v-else
                    class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                  >RÉINITIALISER</button>
                </div>
              </div> -->
            </div>
          </div>
        </card>
        <card>
          <div class="card-body">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="col-xl-1 col-6">
                  <base-input>
                    <el-select
                      class="select-primary mb-3 pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-11 col-6">
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>
              </div>
              <center v-if="$apollo.queries.getPaimentAf.loading">
                <base-button
                  loading
                  round
                  class="btn btn-round btn-just-icon btn-primary mb-2"
                >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
              </center>
              <el-table :data="queriedData" v-else :default-sort = "{prop: 'periode', order: 'descending'}">
                <el-table-column
                  prop="periode"
                  label="date"
                  class="table-striped"
                  min-width="120"
                  sortable
                >
                </el-table-column>
                <el-table-column
                  prop="revenus_credits_mois"
                  label="Revenus"
                  class="table-striped"
                  min-width="120"
                  sortable
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.revenus_credits_mois,true,2) + " €" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="etat_perf"
                  label="Etat"
                  class="table-striped"
                  min-width="120"
                >
                  <template scope="scope">
                    <template v-if="scope.row.etat_perf == '3' || scope.row.etat_coreg=='3'">
                      RÉCEPTIONNÉ
                    </template>
                    <template v-else-if="scope.row.etat_perf == '4' || scope.row.etat_coreg=='4'">
                      PAYÉ
                    </template>
                    <template v-else-if="scope.row.etat_perf == '1' || scope.row.etat_coreg=='1'">
                      AF EMIS
                    </template>
                    <template v-else-if="scope.row.etat_perf == '0' || scope.row.etat_coreg=='0'">
                      REVENUS REPORTES
                    </template>
                    <template v-else-if="scope.row.etat_perf == '6' || scope.row.etat_coreg=='6'">
                      PREPAIMENT CB
                    </template>
                  </template>
                </el-table-column>
                <el-table-column label="Actions" minWidth="130">
                  <div slot-scope="props">
                    <el-tooltip :content="$t('buttons.generatePdf')" effect="light" :open-delay="300" placement="top" v-if="(props.row.etat_perf != '0' && props.row.etat_perf != null) || (props.row.etat_coreg != '0' && props.row.etat_coreg != null)">
                      <base-button
                        @click.native="GeneratePDF(props.row)"
                        class="edit btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="el-icon-document icon-large"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip :content="$t('buttons.uploadInvoice')" effect="light" :open-delay="300" placement="top" v-if="(props.row.etat_perf != '0' && props.row.etat_perf != null) || (props.row.etat_coreg != '0' && props.row.etat_coreg != null)">
                      <base-button
                        @click.native="PrepareUpload(props.row)"
                        class="edit btn-link"
                        type="success"
                        size="sm"
                        :disabled="FileUploadLoader"
                        icon
                        v-if="!$route.params.id_utilisateur"
                      >
                        <i v-if="!FileUploadLoader" class="el-icon-upload icon-large"></i>
                        <i v-else class="el-icon-loading icon-large"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip :content="$t('buttons.visualizeInvoice')" effect="light" :open-delay="300" placement="top" v-if="(props.row.etat_perf != '0' && props.row.etat_perf != null) || (props.row.etat_coreg != '0' && props.row.etat_coreg != null)">
                      <base-button
                        :disabled="!props.row.pdf_perf && !props.row.pdf_coreg"
                        @click.native="OpenPDF(props.row.pdf_perf || props.row.pdf_coreg)"
                        class="edit btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="el-icon-download icon-large"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
                <el-table-column
                  prop="date_modification_perf"
                  label="Date de l'upload"
                  class="table-striped"
                  min-width="170"
                  sortable
                >
                  <template scope="scope">
                    {{ scope.row.date_perf || scope.row.date_coreg }}
                  </template>
                </el-table-column>

                <el-table-column
                  label="PLATEFORME"
                  :min-width="120"
                  class="table-striped"
                >
                  <template scope="scope">
                    <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.flag == 'multi' || scope.row.flag == 'perf' ">
                      <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                    <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.flag == 'multi' || scope.row.flag == 'coreg'">
                      <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <input ref="fileUpload" type="file" v-on:change="handleFileUpload()" hidden>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class>
                <p
                  class="card-category"
                >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import { Table, TableColumn } from "element-ui";
import { BasePagination } from "src/components";
import gql from "graphql-tag";

let today = new Date()

export default {
  apollo: {
    getPaimentAf: {
      client: 'AUTH',
      query: require("src/graphql/Payments_editeur.gql"),
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) {
        this.listPayments = data.getPaimentAf
      },
      variables () {
        return {
          annee: this.YearsSelects.Year,
          id_utilisateur: this.$route.params.id_utilisateur || JSON.parse(localStorage.getItem('utilisateur')).id
        }
      }
    },
  },
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      params: null,
      FileUploadLoader: false,
      File: null,
      total: 0,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      listPayments: [],
      YearsSelects: {
        Year: today.getFullYear(),
        YearList: []
      },
    };
  },
  mounted() {
    if (parseInt(JSON.parse(localStorage.getItem('utilisateur')).id) === 313) {
      this.YearsSelects.YearList = [
        {value: today.getFullYear()},
        {value: today.getFullYear() - 1},
        {value: today.getFullYear() - 2},
        {value: today.getFullYear() - 3},
        {value: today.getFullYear() - 4}
      ]
    }
    else if (parseInt(JSON.parse(localStorage.getItem('utilisateur')).id) === 3031) {
      this.YearsSelects.YearList = [
        {value: today.getFullYear()},
        {value: today.getFullYear() - 1},
        {value: today.getFullYear() - 2},
        {value: today.getFullYear() - 3},
        {value: today.getFullYear() - 4},
        {value: today.getFullYear() - 5},
        {value: today.getFullYear() - 6}
      ]
    }
    else if (parseInt(JSON.parse(localStorage.getItem('utilisateur')).id) === 338) {
      this.YearsSelects.YearList = [
        {value: today.getFullYear()},
        {value: today.getFullYear() - 1},
        {value: today.getFullYear() - 2}
      ]
    }
    else {
      this.YearsSelects.YearList = [
        {value: today.getFullYear()},
        {value: today.getFullYear() - 1}
      ]
    }
  },
  computed: {
    queriedData() {
      let result = this.listPayments;
      this.total = this.listPayments.length
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  methods: {
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    GeneratePDF(row) {
      const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/pdf_facture_af"
      let mois = ((row.periode.split('/')[0]+"").length==1) ? "0"+row.periode.split('/')[0] : row.periode.split('/')[0]
      let annee = row.periode.split('/')[1]
      let accessToken = localStorage.getItem("token_jwt");
      let formData = new FormData()
      formData.append('mois', mois)
      formData.append('annee', annee)
      formData.append('client_perf', (row.id_editeur_perf) ? row.id_editeur_perf : '')
      formData.append('client_coreg', (row.id_editeur_coreg) ? row.id_editeur_coreg : '')
      formData.append('langue', JSON.parse(localStorage.getItem('utilisateur')).language)
      this.axios
        .post(url_api,formData , {
          headers: { authorization: `Bearer ${accessToken}` },
          responseType: 'blob',
        }).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'facture-'+mois+'-'+annee+'.pdf');
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {

        })
    },
    OpenPDF(url) {
      window.open(url, '_blank');
    },
    PrepareUpload(row) {
      this.params = {
        id_paiement_perf: row.num_perf,
        id_paiement_coreg: row.num_coreg,
        period: row.periode
      }
      this.$refs.fileUpload.click()
    },
    handleFileUpload() {
      this.FileUploadLoader = true
      this.File = this.$refs.fileUpload.files[0];
      if (this.File.type != "application/pdf") {
        this.FileUploadLoader = false
        this.$notify({type: 'warning', message: "L'extension du fichier doit être PDF" })
        return
      }
      this.$apollo.mutate({
        client: "AUTH",
        mutation: gql`mutation (
          $id_paiement_perf: Int
          $id_paiement_coreg: Int
          $file: Upload
          $id_utilisateur: Int
          $period: String
        )
        {
          upload_facture(
            id_paiement_perf: $id_paiement_perf
            id_paiement_coreg: $id_paiement_coreg
            file: $file
            id_utilisateur: $id_utilisateur
            period: $period
          )
        }`,
        variables: {
          id_paiement_perf: this.params.id_paiement_perf,
          id_paiement_coreg: this.params.id_paiement_coreg,
          file: this.File,
          id_utilisateur: this.$route.params.id_utilisateur || JSON.parse(localStorage.getItem('utilisateur')).id,
          period: this.params.period
        }
      }).then(response => {
        this.FileUploadLoader = false
        this.$notify({type: 'success', message: this.$t("apiresponse.billuploaded") })
        this.$apollo.queries.getPaimentAf.refresh()
      }).catch(error => {
        this.FileUploadLoader = false
        this.$notify({type: 'warning', message: this.$t("apiresponse.billuploadederror") })
      })
    }
  }
};
</script>

<style>
  .icon-large {
    font-size: 20px !important
  }
</style>
