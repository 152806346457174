<template>
    <div class="content">
      <div class="row mt-5">
        <div class="col-md-12">
          <h3>FACTURES</h3>
          <card>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-8 col-lg-12 col-12">
                  <div class="row">
                    <div class="col-md-3">
                      <base-input label="Année">
                        <el-select class="select-primary"
                          placeholder="CHOISISSEZ"
                          v-model="YearsSelects.Year">
                          <el-option 
                            v-for="option in YearsSelects.YearList"
                            class="select-primary"
                            :value="option.value"
                            :label="option.value"
                            :key="option.value">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
          <card>
            <div class="card-body">
              <div>
                <div
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <div class="col-xl-1 col-6">
                    <base-input>
                      <el-select
                        class="select-primary mb-3 pagination-select"
                        v-model="pagination.perPage"
                        placeholder="Per page"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in pagination.perPageOptions"
                          :key="item"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-xl-11 col-6">
                    <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                  </div>
                </div>
                <center v-if="$apollo.queries.getFactureAn.loading">
                  <base-button 
                    loading 
                    round 
                    class="btn btn-round btn-just-icon btn-primary mb-2"
                  >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                </center>
                <el-table :data="queriedData" v-else>
                  <el-table-column
                    prop="ref_perf"
                    label="Ref"
                    class="table-striped"
                    min-width="120"
                  >
                    <template scope="sope">
                      {{ sope.row.ref_perf || sope.row.ref_coreg }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="periode_perf"
                    label="Période"
                    class="table-striped"
                    min-width="120"
                  >
                    <template scope="sope">
                      {{ sope.row.periode_perf || sope.row.periode_coreg }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="statut_coreg"
                    label="Statut"
                    class="table-striped"
                    min-width="120"
                  >
                    <template scope="sope">
                      {{ getStatus(sope.row.statut_perf) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="montant_ht_perf"
                    label="Montant (HT)"
                    class="table-striped"
                    min-width="130"
                  >
                    <template scope="sope">
                      <template v-if="sope.row.flag == 'perf'">
                        {{ formatThounsends(sope.row.montant_ht_perf,true,1) + " €" }}
                      </template>
                      <template v-else-if="sope.row.flag == 'coreg'">
                        {{ formatThounsends(sope.row.montant_ht_coreg,true,1) + " €" }}
                      </template>
                      <template v-else-if="sope.row.flag == 'multi'">
                        {{ formatThounsends((sope.row.montant_ht_coreg + sope.row.montant_ht_perf),true,1) + " €" }}
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="montant_ttc_perf"
                    label="Montant (TTC)"
                    class="table-striped"
                    min-width="135"
                  >
                    <template scope="sope">
                      <template v-if="sope.row.flag == 'perf'">
                        {{ formatThounsends(sope.row.montant_ttc_perf,true,1) + " €" }}
                      </template>
                      <template v-else-if="sope.row.flag == 'coreg'">
                        {{ formatThounsends(sope.row.montant_ttc_coreg,true,1) + " €" }}
                      </template>
                      <template v-else-if="sope.row.flag == 'multi'">
                        {{ formatThounsends((sope.row.montant_ttc_coreg + sope.row.montant_ttc_perf),true,1) + " €" }}
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column label="Actions" minWidth="130">
                    <div slot-scope="props">
                      <el-tooltip content="Téléchargement" effect="light" :open-delay="300" placement="top">
                        <base-button
                          @click.native="Download(props.row)"
                          class="edit btn-link"
                          type="success"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons el-icon-download"></i>
                        </base-button>
                      </el-tooltip>

                      <el-tooltip content="Règlement en ligne" effect="light" :open-delay="300" placement="top">
                        <base-button
                          @click.native="OnlinePay(props.row)"
                          :disabled="props.row.statut_perf === 2 || props.row.statut_perf === 8 || props.row.statut_perf === 9 || props.row.statut_perf === 11 ||
                                     props.row.statut_coreg === 2 || props.row.statut_coreg === 8 || props.row.statut_coreg === 9 || props.row.statut_coreg === 11          
                          "
                          class="edit btn-link"
                          type="success"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons el-icon-document"></i>
                        </base-button>
                      </el-tooltip>
                    </div>
                  </el-table-column>
                  <el-table-column
                    label="Platforme"
                    :min-width="120"
                    class="table-striped"
                  >
                    <template scope="scope">
                      <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.flag == 'multi' || scope.row.flag == 'perf' ">
                        <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                          <g>
                            <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                            <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                              c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                              h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                              />
                          </g>
                        </svg>
                      </span>
                      <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.flag == 'multi' || scope.row.flag == 'coreg'">
                        <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                          <g>
                            <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                            <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                              c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                              h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                              />
                          </g>
                        </svg>
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class>
                  <p
                    class="card-category"
                  >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></base-pagination>
              </div>
            </div>
          </card>
        </div>
      </div>
      <modal
        :show.sync="ShowPDF"
        body-classes="p-0"
        class="modal-listbase"
        :show-close="true"
        >
          <base-button style="float: right;" @click.native="ShowPDF = false" class="like btn-link" type="danger" size="sm" icon>
            <i class="tim-icons icon-simple-remove"></i>
          </base-button>
          <object :data="PDFURL" type="application/pdf" style="width: 100%;height: 900px;">
            <iframe :src="PDFURL"></iframe>
          </object>
      </modal>
    </div>
  </template>
  
  <script>
  import { Select, Option, DatePicker } from "element-ui";
  import { Table, TableColumn } from "element-ui";
  import { BasePagination } from "src/components";
  import gql from "graphql-tag";
  import axios from 'axios';
  import {Modal} from 'src/components'

  let today = new Date()

  export default {
    apollo: {
      getFactureAn: {
        client: 'AUTH',
        query: require("src/graphql/Factures_annonceur.gql"),
        fetchPolicy: 'network-only',
        result({ data, loader, networkStatus }) {
          this.listFactures = data.getFactureAn
        },
        variables () {
          return {
            annee: this.YearsSelects.Year,
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
            // id_utilisateur: 383
          }
        }
      },
    },
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      Modal
    },
    data() {
      return {
        PDFURL: '',
        ShowPDF: false,
        total: 0,
        listFactures: [],
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        YearsSelects: {
          Year: today.getFullYear(),
          YearList: [
            {value: today.getFullYear()},
            {value: today.getFullYear() - 1}
          ]
        },
        ListEtat: [
          { value: 0, label: "-"},
          { value: 1, label: "ECHOUÉ"},
          { value: 2, label: "PAYEE"},
          { value: 3, label: "RELANCE1"},
          { value: 4, label: "RELANCE2"},
          { value: 5, label: "RELANCE3"},
          { value: 6, label: "LRAR"},
          { value: 7, label: "-"},
          { value: 8, label: "PAYEE AVEC PAYPAL"},
          { value: 9, label: "PAYEE AVEC SOGENACTIF"},
          { value: 11, label: "PRÉPAIEMENT"},
          { value: null, label: "-"},
        ]
      };
    },
    computed: {
      queriedData() {
        let result = this.listFactures;
        this.total = this.listFactures.length
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    methods: {
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      Download(row) {
        let periode = row.periode_perf || row.periode_coreg
        const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/pdf_facture"
        let accessToken = localStorage.getItem("token_jwt");
        let formData = new FormData()
        formData.append('mois', ((periode.split('/')[0]+"").length==1) ? "0"+periode.split('/')[0] : periode.split('/')[0])
        formData.append('annee', periode.split('/')[1])
        formData.append('client_perf', row.client_perf)
        formData.append('client_coreg', row.client_coreg)
        this.axios
          .post(url_api,formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
            responseType: "blob"
          }).then(function(response) {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            this.ShowPDF = true
            this.PDFURL = window.URL.createObjectURL(blob)
          }.bind(this))
          .catch(function (error) {
            console.log(error.message);
          })
      },
      getStatus(id) {
        return this.ListEtat.find(x => x.value === id).label
      },
      OnlinePay(row) {
        localStorage.setItem("facture", JSON.stringify(row))
        this.$router.push({ name: "prepaiement"})
      }
    }
  };
  </script>